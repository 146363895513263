import { MediaMatcher } from '@angular/cdk/layout';
import { DatePipe, DecimalPipe, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatRadioChange } from '@angular/material/radio';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { EMPTY, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import { ToNumberPipe } from 'src/app/_shared/pipe/to-number.pipe';
import { ActivityLoggerService } from 'src/app/_shared/service/activity-logger.service';
import { DealAnalysisService } from 'src/app/_shared/service/deal-analysis.service';
import { DealAnalysisState } from 'src/app/_shared/state/deal-analysis.state';
import { ImageGalleryState } from 'src/app/_shared/state/image-gallery.state';
import { AppState } from 'src/app/app.state';
import { CreateMyHomeDialogueComponent } from 'src/app/my-home-page/component/create-my-home-dialogue/create-my-home-dialogue.component';
import { MyHomeState } from 'src/app/my-home-page/state/my-home.state';
import { ProfileService } from 'src/app/profile/profile.service';
import { CurrencyFormatterPipe } from 'src/app/shared/pipe/currency-formatter.pipe';
import { IsValueExistPipe } from 'src/app/shared/pipe/is-value-exist.pipe';
import { AgentContactService } from 'src/app/workspace/service/agent-contact.service';
import { PropertyService as _PropertyService } from 'src/app/workspace/service/property.service';
import { SharePropertyService } from 'src/app/workspace/service/share-property.service';
import { AgentContactState } from 'src/app/workspace/state/agent-contact.state';
import { PaymentCalculatorState } from 'src/app/workspace/state/payment-calculator.state';
import { PropertyState } from 'src/app/workspace/state/property.state';
import MESSAGE from 'src/assets/resource/strings.json';
import constant from 'src/constant.json';
import { environment } from 'src/environments/environment';
import { LinkShareComponent } from '../../../_shared/component/link-share/link-share.component';
import { AppConfig } from '../../interface/company.interface';
import { PropertyService } from '../../service/property.service';
import { FavoriteState } from '../../state/favorite.state';
import { UserState } from '../../state/user.state';
import { ActivateModalComponent } from '../activate-account/activate-modal.component';
import { SignupDialogComponent } from '../login-signup/signup-dialog.component';
import { AgentContactScheduleComponent } from './component/agent-contact-schedule/agent-contact-schedule.component';
import { ContactSellingConsultantComponent } from './component/contact-selling-consultant/contact-selling-consultant/contact-selling-consultant.component';
import { PropertySheetFacade } from './component/property-sheet.facade';

interface TransactionDetails {
  slNo: number;
  TransactionContractDate: string;
  TransactionType: string;
  ClosePrice: string;
}

@Component({
  selector: 'app-property-details-sheet',
  templateUrl: './property-details-sheet.component.html',
  styleUrls: ['./property-details-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyDetailsSheetComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() change: EventEmitter<MatRadioChange>;
  @ViewChild('mapElement', { read: ElementRef, static: false }) mapElement: ElementRef;
  @ViewChild('inviteUrlField') inviteUrlField: ElementRef;
  @ViewChild('singlePropertyDetail') singlePropertyDetail: ElementRef;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('localTrends') localTrendsExpansionPanel: MatExpansionPanel;
  @ViewChild('schools') schoolExpansionPanel: MatExpansionPanel;
  @ViewChild('overview') private overview: ElementRef;
  @ViewChild('propertyInformation') private propertyInformation: ElementRef;
  @ViewChild('valueAndTaxInformation') private valueAndTaxInformation: ElementRef;
  @ViewChild('schoolsInformation') private schoolsInformation: ElementRef;
  @ViewChild('trends') private trends: ElementRef;
  @ViewChild('more') private more: ElementRef;

  agentInfo: object = {};
  address = {};
  fullAddress: string;
  propertyLocationMap: google.maps.Map;
  markerList: Array<google.maps.Marker> = [];
  setStreetViewEvent;
  setBirdsEyeViewEvent;
  stringRes: any = MESSAGE;
  dealList: Array<any> = [];
  PMXPropertyId;
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  activePropertyDetails: any = {};
  activeHomeValue: any = {};
  activeAVMHistory = [];
  firstAVMValue = null;
  totalLoanBalanceAtMonthEnd = null;
  totalOwnerEquity = null;
  selectedMapView: string;
  isStreetView = true;
  defaultPlace: { lat: number; lng: number };
  birdsEyeView: any;
  isContactAvailable = false;
  contactInfoLoading = false;
  streetView = null;
  displayedColumns: Array<any> = ['slNo', 'Transaction Date', 'Transaction Type', 'Close Price'];
  activeTransactionHistory = [];
  // MatTableDataSource<TransactionDetails>;
  AVMHistoryDetails: Array<any> = [];
  estimatedLtv = null;
  activeMortgageHistory: Array<any> = [];
  activeLocalTrendsForZip = null;
  localTrendsLoaderStatus = true;
  activeSchoolDistrictsForProperty: Array<any> = [];
  activeSchoolsForProperty: Array<any> = [];
  schoolsForPropertyLoaderStatus = false;
  showMultipleProperty = false;
  multiplePropertyList: Array<any> = [];
  activeDistressedProperty = null;
  distressedPropertyStatus = false;
  auctionList: Array<any> = [];
  auctions: Array<any> = [];
  showAuction = false;
  limit = 1;
  propertyInfo = null;
  loanApplicationUrlRequestInProgress = false;
  // listingInfoFlag = false;
  isDealSheetOpened: boolean;
  associationFee: any;
  userInfo: any;
  auctionDisplayedColumns: Array<any> = [
    'slNo',
    'Auction Date',
    'Auction Time',
    'Auction Site Address',
    'Auction Site City',
    'Auction Site Postal Code',
  ];
  activeCountyValue = null;
  propertyListingConfig: any;
  listingImages: Array<any> = [];
  listingInfo: any;
  hideDeal: boolean = true;
  canViewAgentContactInfo: boolean;
  listingData: any = {};
  activePropertyFlag: boolean = false;
  activePropertyDetailsFlag: boolean = false;
  copyBtnText = 'copy';
  shareUrl: string;
  mlsBoard: any;
  RawMlsStatus: any;
  StandardStatus: any;
  propertyDetails: any;
  listingDataFilterclass: any;
  private renderer: Renderer2;
  isPropertyDataExists = true;
  functionCalled = false;
  companyAppConfig: AppConfig;
  companyName: string = '';
  alternateCompanyName: string = '';
  userType: string = '';
  listedByContact: string = '';
  mlsLogo: any = null;
  mlsDisclaimer: string = '';
  showMore = false;
  currentDiv = 'overview';
  onScrollEvent = {
    'padding-top': '0px',
  };
  isMyHomeClicked: false;
  private prospektrBackendBaseURL = environment.prospektrBackendBaseURL;

  // Subscription to unsubscribe on component destroy
  onDestroyNotifier$ = new Subject();
  isOffmarketProperty: boolean = false;
  agentData: any;
  isActiveProperty: boolean = false;
  populateAddress: any;
  displayCta: boolean = true;
  copyURL: string;
  propmixAccessToken: String;
  isAvoidListing: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    public propertySheetFacade: PropertySheetFacade,
    public dialog: MatDialog,
    private isValueExistPipe: IsValueExistPipe,
    private currencyFormatterPipe: CurrencyFormatterPipe,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private sanitizer: DomSanitizer,
    private dealAnalysisService: DealAnalysisService,
    private dealAnalysisState: DealAnalysisState,
    public authorizeService: AuthorizeService,
    public imageGalleryState: ImageGalleryState,
    private userState: UserState,
    private paymentCalculatorState: PaymentCalculatorState,
    private toNumberPipe: ToNumberPipe,
    private activityLoggerService: ActivityLoggerService,
    private propertyState: PropertyState,
    public agentContactService: AgentContactService,
    public agentContactState: AgentContactState,
    private matDialog: MatDialog,
    public appState: AppState,
    private propertyService: PropertyService,
    private favoriteState: FavoriteState,
    private sharePropertyService: SharePropertyService,
    private snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _propertyService: _PropertyService,
    private _zone: NgZone,
    private profileService: ProfileService,
    private _location: Location,
    private myHomeState: MyHomeState
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.agentContactState.propertyClientData$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      this.agentInfo = res.agent ? res.agent : {};
      this.changeDetectorRef.detectChanges();
    });
    this.userState.userDetail$
      .pipe(takeUntil(this.onDestroyNotifier$))
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(() => {
        this.canViewAgentContactInfo = this.authorizeService.canView('agentContact');
      });
    this.propertyService.getToken().subscribe(
      (response) => {
        this.propmixAccessToken = response;
      },
      (error) => {
        console.error('Error fetching token:', error);
      }
    );

    this.appState.companyInfo$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.companyAppConfig = res?.appConfig;
      this.mlsDisclaimer = res?.mlsDisclaimer;

      this.propertyListingConfig = res && res.propertyListingConfig ? res.propertyListingConfig : {};
    });

    this.propertyState.avoidListing$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.isAvoidListing = res;
    });

    this.propertyState.propertyDetailsSheetInput$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((data: any) => {
      if (!data) {
        return;
      }
      if (data.Address) {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        let url: any = this.propertySheetUrl(data);
        let updatedUrl = this.router.createUrlTree([url], { queryParams: queryParams }).toString();
        this._location.replaceState(updatedUrl);
      }
      this.isPropertyDataExists = true;
      this.sidenav.open();
      this.propertyInfo = data;
      if ((data && data.Lst_StandardStatus == 'Sold') || data.Lst_StandardStatus == 'Cancel / withdrawn') {
        this.isOffmarketProperty = true;
        this.isActiveProperty = false;
      }
      if (
        ['active', 'pending', 'activeundercontract'].includes(
          this.propertyInfo?.Lst_StandardStatus?.toLowerCase() || this.listingData?.StandardStatus?.toLowerCase()
        )
      ) {
        this.isActiveProperty = true;
        this.isOffmarketProperty = false;
      }
      setTimeout(() => {
        this.triggerPropertyDetailsSheetInput(data, this.isAvoidListing);
      }, 1000);
    });
    this.dealAnalysisState.dealListSub$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      this.dealList = res;
    });
    this.dealAnalysisState.openDealAnalysisSheet.subscribe((res: any) => {
      this.changeDetectorRef.detectChanges();
      this.isDealSheetOpened = res;
    });
    this.myHomeState.isMyHomeAddress.subscribe((res: any) => {
      this.isMyHomeClicked = res;
    });

    this.propertyState.activeHomeValue$.subscribe((res) => {
      //console.log('$#%@ detail-sheet-component-6'); // do not remove
      this.activeHomeValue = res;
    });

    this.propertySheetFacade
      .getActiveMultiplePropertyDetails$()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        //console.log('$#%@ detail-sheet-component-1'); // do not remove
        if (res && res.length > 0) {
          setTimeout(() => {
            this.showMultipleProperty = true;
            this.multiplePropertyList = res;
            this.propertySheetFacade.setMultiplePropertyDetailsSheetLoaderStatus(false);
          });
        } else {
          this.showMultipleProperty = false;
          this.multiplePropertyList = [];
        }
      });

    // this.propertySheetFacade.getActiveContactInfo$().pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
    //   //console.log('$#%@ detail-sheet-component-8'); // do not remove
    //   this.propertySheetFacade.setContactInfoLoaderStatus(false);
    //   if (res && !this.isEmptyObject(res)) {
    //     this.isContactAvailable = true;
    //     this.contactInfoObj = res;
    //   } else {
    //     this.isContactAvailable = false;
    //   }
    // });
    // this.propertySheetFacade.getContactInfoLoaderStatus$().pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
    //   //console.log('$#%@ detail-sheet-component-9'); // do not remove
    //   this.contactInfoLoading = res;
    // });
    this.propertySheetFacade
      .getActiveSchoolDistrictsForProperty$()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        //console.log('$#%@ detail-sheet-component-11'); // do not remove
        if (res && Object.keys(res).length && Object.keys(res).includes('districts')) {
          this.activeSchoolDistrictsForProperty = res.districts;
        } else {
          this.activeSchoolDistrictsForProperty = [];
        }
        this.propertySheetFacade.setSchoolsForPropertyLoaderStatus(false);
      });
    this.propertySheetFacade
      .getActiveSchoolsForProperty$()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        //console.log('$#%@ detail-sheet-component-12'); // do not remove
        if (res && Object.keys(res).length && Object.keys(res).includes('schools')) {
          this.activeSchoolsForProperty = res.schools;
        } else {
          this.activeSchoolsForProperty = [];
        }
      });
    this.propertySheetFacade
      .getSchoolsForPropertyLoaderStatus$()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        //console.log('$#%@ detail-sheet-component-13'); // do not remove
        this.schoolsForPropertyLoaderStatus = res;
      });

    this.propertyState.activePropertyDetails$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (Object.keys(res).length == 0 && this.propertyInfo) {
        this.isPropertyDataExists = false;
        this.changeDetectorRef.detectChanges();
        return;
      }
      this.isPropertyDataExists = true;
      //console.log('$#%@ detail-sheet-component-3'); // do not remove
      if (res && !this.isEmptyObject(res) && res.basic) {
        setTimeout(() => {
          this.showMultipleProperty = this.showMultipleProperty || false;
        });

        if (res.basic.Address) {
          let propertyStreetAddress = res.basic?.Address.split(',');
          res.basic['propertyAddress'] = propertyStreetAddress[0];
        }

        // if (res.basic.Lst_StandardStatus == 'Sold' || res.basic.Lst_StandardStatus == 'Cancel / withdrawn') {
        //   this.isOffmarketProperty = true;
        //   console.log('entered my condition.', this.isOffmarketProperty)
        // } else {
        //   console.log('failed entered my condition.', this.isOffmarketProperty)
        // }
        res.basic['isFavorite'] = this.favoriteState.favoritePropertyIdListValue.includes(res.basic.PMXPropertyId);
        this.activePropertyDetails = res.basic;
        // Getting the data for mortgageHistory from Property-details
        if (res.mortgageList && res.mortgageList.length != 0) {
          this.activeMortgageHistory = res && res.mortgageList ? res.mortgageList : [];
          this.findTotalLoanBalanceAtMonthEnd(this.activeMortgageHistory);
        }
        this.paymentCalculatorState.propertyTax = this.activePropertyDetails.TaxAnnualAmount
          ? this.toNumberPipe.transform(this.activePropertyDetails.TaxAnnualAmount) / 12
          : 0;

        let landValue =
          this.toNumberPipe.transform(this.activePropertyDetails.AssessedLandValue) ||
          this.toNumberPipe.transform(this.activePropertyDetails.AssessedValue) * 0.2 ||
          this.toNumberPipe.transform(this.activePropertyDetails.Lst_ListPrice) * 0.2 ||
          0;
        this.paymentCalculatorState.landValue = landValue;

        this.paymentCalculatorState.state = this.activePropertyDetails.StateOrProvince || 'DC';
        this.activePropertyDetailsFlag = true;
        if (this.activePropertyDetails.Address) {
          let url = this.propertySheetUrl(this.activePropertyDetails);
          url = `https://${this.appState.companyInfoValue.baseUrl}${url}`;
          this.copyURL = url;
        }

        this.shareUrl = `${this.prospektrBackendBaseURL}socialMediaShare?propertyId=${this.activePropertyDetails.PMXPropertyId}&companyId=${this.appState.companyInfoValue.id}`;
        this.userType = this.userState.userDetails?.userType;
        if (
          this.userType === constant.USER_TYPE.loanOfficer ||
          this.userType === constant.USER_TYPE.companyManager ||
          this.userType === constant.USER_TYPE.agent
        ) {
          this.displayCta = false;
        }
        this.companyName = this.appState && this.appState.companyInfoValue.alias;
        this.alternateCompanyName = this.appState && this.appState.companyInfoValue.alterNativeName;
        if (this.activePropertyDetails.PMXPropertyId) {
          this.PMXPropertyId = res.basic.PMXPropertyId;
          this.getDeal();
          var propertyInfomation = {
            PMXPropertyId: this.activePropertyDetails.PMXPropertyId,
            PropertySubType: this.activePropertyDetails.PropertySubType,
            listPrice: this.activePropertyDetails.Lst_ListPrice,
            zipCode: this.activePropertyDetails.PostalCode,
            address: {
              street: this.activePropertyDetails.StreetAddress,
              city: this.activePropertyDetails.City,
              state: this.activePropertyDetails.StateOrProvince,
              zip: this.activePropertyDetails.PostalCode,
            },
            fullAddress: res.Address,
          };
          this.populateAddress = propertyInfomation.address;
          this.populateAddress.propertyClaimFlag = true;
          if (this.appState.authTokenValue?.idToken) {
            if (this.propertyInfo.logInput) {
              this.logPropertyDetailActivity(
                constant.LOGGER.propertyDetails.category,
                constant.LOGGER.propertyDetails.action.view.key,
                propertyInfomation
              );
            }
          }
          this.paymentCalculatorState.homePrice = this.activePropertyDetails.Lst_ListPrice;
        }
        this.address = {
          street: this.activePropertyDetails.StreetAddress,
          city: this.activePropertyDetails.City,
          State: this.activePropertyDetails.StateOrProvince,
          zip: this.activePropertyDetails.PostalCode,
        };
      }
      // Getting the data for transactionHistory from Property-details
      if (res.transactionList) {
        let sortedTransactionList = this.sortTransactionList(res.transactionList);
        this.activeTransactionHistory = sortedTransactionList;
        // ? new MatTableDataSource<TransactionDetails>(sortedTransactionList)
        // : new MatTableDataSource<TransactionDetails>([]);
      }
      // Getting the data for countyDetails from Property-details
      if (res.countyDetails) {
        this.activeCountyValue = res && res.countyDetails.countyName ? res.countyDetails.countyName : '--';
      }
      // Getting the data for distressedDetails from Property-details
      if (res.distressedDetails) {
        if (res.distressedDetails.foreclosure) {
          this.activeDistressedProperty = res.distressedDetails.foreclosure;
          this.distressedPropertyStatus = true;
        }
        if (res.distressedDetails.auction) {
          this.auctionList = res && res.distressedDetails.auction ? res.distressedDetails.auction.reverse() : [];
        } else {
          this.distressedPropertyStatus = false;
        }
      }

      // Getting the data for listingDetails from Property-details
      if (res.listing) {
        this.listingData = res.listing ? res.listing : {};
        // this.mlsBoard = this.listingData.MlsBoard ? this.listingData.MlsBoard : '';
        this.RawMlsStatus = this.listingData.RawMlsStatus ? this.listingData.RawMlsStatus : '';
        this.PMXPropertyId = this.listingData.PMXPropertyId;

        this.mlsDisclaimer = this.generateDisclaimer(this.appState.companyInfoValue?.mlsDisclaimer, {
          mlsName: this.listingData?.OriginatingSystemName,
        });

        // if (this.propertyListingConfig.hasOwnProperty(this.mlsBoard)) {
        //   let configData = this.propertyListingConfig[this.mlsBoard];
        //   if ((configData.cancelledListings == true || configData.expiredListings == true) && (this.mlsStatus == 'Active')) {
        //     this.listingInfoFlag = true;
        //   } else if ((configData.cancelledListings == false || configData.expiredListings == false) && (this.mlsStatus == 'Active')) {
        //     this.listingInfoFlag = true;
        //   } else {
        //     this.listingInfoFlag = false;
        //   }
        // }
        if (this.listingData?.MlsBoard) {
          this._propertyService.getMlsImageInfo(this.listingData.MlsBoard);
        }
        this.propertyState.mlsImage$.subscribe((res: any) => {
          if (res) {
            this.mlsLogo = res.logoUrl || '';
            if (res.disclaimer?.trim()) this.mlsDisclaimer = res.disclaimer;
          }
        });
        //  Agent Contact Details and Payment  calculator
        if (
          ['active', 'pending', 'activeundercontract'].includes(
            this.propertyInfo?.Lst_StandardStatus?.toLowerCase() || this.listingData?.StandardStatus?.toLowerCase()
          )
        ) {
          this.activePropertyFlag = true;
        }
        const associationFee1 = this.calculateHoaFee(
          this.toNumberPipe.transform(res.listing?.AssociationFee ? res.listing?.AssociationFee : 0),
          res.listing?.AssociationFeeFrequency
        );
        const associationFee2 = this.calculateHoaFee(
          this.toNumberPipe.transform(res.listing?.AssociationFee2),
          res.listing?.AssociationFee2Frequency
        );
        this.associationFee = associationFee1 + associationFee2;
        this.paymentCalculatorState.hoaFees = [this.associationFee];

        if (this.listingData.MlsBoard == 'MREIS') {
          if (
            this.listingData.InternetAttributionContact &&
            this.listingData.InternetAttributionContact.ListOfficeName
          ) {
            this.listedByContact += this.listingData.InternetAttributionContact.ListOfficeName;
            if (
              this.listingData.InternetAttributionContact &&
              this.listingData.InternetAttributionContact.InternetAttributionContact
            ) {
              this.listedByContact += ' | ';
              this.listedByContact += this.listingData.InternetAttributionContact.InternetAttributionContact;
            }
          }
        } else {
          this.listedByContact = '';
          if (this.listingData.ListOfficePhone) {
            this.listedByContact += this.listingData.ListOfficePhone;
          }
          if (this.listingData.ListAgentEmail) {
            if (this.listedByContact) {
              this.listedByContact += ' | ';
            }
            this.listedByContact += this.listingData.ListAgentEmail;
          }
        }
        if (!this.listedByContact) {
          this.listedByContact = '--';
        }
      }

      if (!res.listing) {
        this.mlsDisclaimer = this.appState.companyInfoValue?.noListingDisclaimer;
      }
      // Getting the data for avmList from Property-details
      if (res.avmList != null) {
        this.firstAVMValue = ((res.avmList[0] && res.avmList[0].AVMValue) || 0).toString();
        this.firstAVMValue = parseInt(this.firstAVMValue.replace(/[$,]/g, ''), 10);
        this.activeAVMHistory = res && res.avmList ? res.avmList : [];
        if (this.activeAVMHistory.length)
          if (
            this.propertyInfo &&
            this.propertyInfo.Lst_StandardStatus !== 'Active' &&
            this.propertyInfo.Lst_StandardStatus !== 'Active Under Contract' &&
            this.propertyInfo.Lst_StandardStatus !== 'Pending'
          ) {
            this.paymentCalculatorState.homePrice = this.toNumberPipe.transform(
              this.activePropertyDetails.Lst_ListPrice
                ? this.activePropertyDetails.Lst_ListPrice
                : this.activeAVMHistory[0].AVMValue
            );
            this.activePropertyFlag = true;
          }
        this.formatAVMHistoryDetails(this.activeAVMHistory);
        this.setTotalOwnerEquity();
      }
      // Getting the data for imageUrls from Property-details
      if (res.imageList && Array.isArray(res.imageList)) {
        this.changeDetectorRef.detectChanges();
        this.listingImages = [];
        res.imageList.forEach((element) => {
          // this.galleryImagesList = res.map((img) => ({
          // let image = {
          //   big: element + '&height=500',
          //   small: element + '&height=100',
          //   medium: element + '&height=400',
          // };
          //  }));
          // element = element + '&height=500';
          this.listingImages.push(element);
        });
        this.viewImages(this.listingImages);
      } else {
        this.listingImages = [];
      }
      setTimeout(() => {
        this.changeDetectorRef.detectChanges();
        this.propertySheetFacade.setPropertyDetailsSheetLoaderStatus(false);
      }, 1000);
      this.selectedMapView = 'birdsEyeView';
      if (res.basic) {
        let res_Latitude = res.basic.Latitude ? res.basic.Latitude : this.propertyInfo.Latitude;
        let res_Longitude = res.basic.Latitude ? res.basic.Longitude : this.propertyInfo.Longitude;
        this.defaultPlace = { lat: Number(res_Latitude), lng: Number(res_Longitude) };
        // this.changeDetectorRef.detectChanges();
      }
    });
  }

  sortTransactionList(transactionList: any[]) {
    if (!transactionList || transactionList.length === 0) {
      return [];
    }
    transactionList.sort((a, b) => {
      return new Date(b.TransactionContractDate).getTime() - new Date(a.TransactionContractDate).getTime();
    });
    return transactionList;
  }

  onVisible(list) {
    const io = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          this.currentDiv = entry.target.id;
        } else {
          io.disconnect();
        }
      });
    });
    list.forEach((el) => {
      io.observe(el);
    });
  }

  onMouseWheel(evt) {
    this.onVisible(document.querySelectorAll('.sectioninfo'));
  }

  ngAfterViewInit(): void {
    this.sidenav.openedChange
      .pipe(
        switchMap((isSideNavOpened: boolean) => {
          if (isSideNavOpened && this.mapElement?.nativeElement) {
            this.propertyLocationMap = this._zone.runOutsideAngular(() => {
              return new google.maps.Map(this.mapElement?.nativeElement, {
                zoom: 17,
                gestureHandling: 'cooperative',
              });
            });
            return this.propertyState.activePropertyDetails$;
          }
          return EMPTY;
        })
      )
      .subscribe((property) => {
        if (property.basic) {
          property = property.basic;
        }
        if (property?.Latitude && property?.Longitude) {
          const propertyLocation: google.maps.LatLngLiteral = {
            lat: Number(property.Latitude),
            lng: Number(property.Longitude),
          };
          this.propertyLocationMap.setCenter(propertyLocation);
          new google.maps.Marker({
            map: this.propertyLocationMap,
            position: propertyLocation,
            animation: google.maps.Animation.DROP,
          });

          this.setBirdsEyeViewEvent = propertyLocation;
          this.setStreetViewEvent = propertyLocation;
        }
      });
  }

  scrollTo(id) {
    this.currentDiv = id;
    document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.onScrollEvent['padding-top'] = '60px';
  }

  getDeal() {
    if (this.PMXPropertyId) {
      if (this.authorizeService.canView('dealAnalysis')) {
        this.dealAnalysisService
          .fetchDeals({
            PMXPropertyId: this.PMXPropertyId,
          })
          .pipe(takeUntil(this.onDestroyNotifier$))
          .subscribe((status: any) => {
            this.hideDeal = status.isLoading;
          });
      }
    }
  }

  openDealAnalysisSheetWithSavedDetails(dealDetails) {
    if (this.authorizeService.canAct('dealAnalysis')) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          'deal-analysis-id': dealDetails._id,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  toggle() {
    this.showAuction = !this.showAuction;
    this.limit = this.limit === 1 ? this.auctionList.length : 1;
    document.getElementById('brokerInfo').scrollIntoView();
  }

  openDealAnalysisSheet(property) {
    let address = {
      city: this.propertyState.activePropertyDetailsValue['basic']?.City,
      state: this.propertyState.activePropertyDetailsValue['basic']?.StateOrProvince,
      street: this.propertyState.activePropertyDetailsValue['basic']?.StreetAddress,
      zip: this.propertyState.activePropertyDetailsValue['basic']?.PostalCode,
    };
    if (this.appState.authTokenValue?.idToken && this.userState.userDetails?.roles?.includes('investor'))
      this.logUserActivity(
        constant.LOGGER.propertyDetails.category,
        constant.LOGGER.propertyDetails.action.propertyAnalyze.key,
        this.propertyState.activePropertyDetailsValue['basic'].PMXPropertyId,
        address
      );

    if (this.authorizeService.canAct('dealAnalysis')) {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          'deal-analysis-property-id': property.PMXPropertyId,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  ngOnDestroy() {
    // this.logPropertyDetailActivity(
    //   constant.LOGGER.PROPERTY_DETAILS.category,
    //   constant.LOGGER.PROPERTY_DETAILS.action.close,
    //   { PMXPropertyId: this.PMXPropertyId }
    // );
    this.agentInfo = {};
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  getPropensityDetails(propensityValue) {
    if (propensityValue && propensityValue === null) {
      return {};
    } else if (propensityValue !== null) {
      propensityValue = parseFloat(propensityValue);
      if (propensityValue >= 75.01 && propensityValue < 100) {
        return {
          propensityType: 'HOT',
          propensityToSellTypeClass: 'scr_hot',
        };
      } else if (propensityValue > 50 && propensityValue <= 75) {
        return {
          propensityType: 'WARM',
          propensityToSellTypeClass: 'scr_warm',
        };
      } else if (propensityValue >= 0 && propensityValue < 50) {
        return {
          propensityType: 'COLD',
          propensityToSellTypeClass: 'scr_cold',
        };
      }
    }
  }

  formatAVMHistoryDetails(avmHistory) {
    this.AVMHistoryDetails = [];
    avmHistory.forEach((avm) => {
      avm.AVMValue = this.currencyFormatterPipe.transform(this.isValueExistPipe.transform(avm.AVMValue));
      avm.AVMLowValue = this.currencyFormatterPipe.transform(this.isValueExistPipe.transform(avm.AVMLowValue));
      avm.AVMHighValue = this.currencyFormatterPipe.transform(this.isValueExistPipe.transform(avm.AVMHighValue));
      avm.AVMValuationDate = this.datePipe.transform(
        this.isValueExistPipe.transform(avm.AVMValuationDate),
        'MM/dd/yyyy'
      );
      this.AVMHistoryDetails.push(avm);
    });
    this.AVMHistoryDetails.sort((a: any, b: any) => {
      return new Date(a.AVMValuationDate).getTime() - new Date(b.AVMValuationDate).getTime();
    });
  }

  findTotalLoanBalanceAtMonthEnd(mortgageHistory) {
    this.totalLoanBalanceAtMonthEnd = 0;
    mortgageHistory.forEach((mortgage) => {
      this.totalLoanBalanceAtMonthEnd = mortgage.LoanBalanceAtMonthEnd
        ? this.totalLoanBalanceAtMonthEnd + mortgage.LoanBalanceAtMonthEnd
        : this.totalLoanBalanceAtMonthEnd;
    });
    this.setTotalOwnerEquity();
  }

  setTotalOwnerEquity() {
    if (this.firstAVMValue && this.totalLoanBalanceAtMonthEnd) {
      this.totalOwnerEquity = this.firstAVMValue - this.totalLoanBalanceAtMonthEnd;
      this.estimatedLtv = Math.ceil((this.totalLoanBalanceAtMonthEnd / this.firstAVMValue) * 100);
      if (this.isValueExistPipe.transform(this.estimatedLtv) != '--')
        this.estimatedLtv = this.decimalPipe?.transform(this.isValueExistPipe.transform(this.estimatedLtv)) + '%';
    } else if (this.firstAVMValue) {
      this.totalOwnerEquity = this.firstAVMValue;
      this.estimatedLtv = null;
    }
  }

  onViewChange(viewChange: MatRadioChange) {
    this.selectedMapView = viewChange.source.value;
    if (this.selectedMapView === 'birdsEyeView') {
      this.setStreetViewEvent = null;
      this.isStreetView = false;
    } else {
      this.setStreetViewEvent = this.defaultPlace;
      this.isStreetView = true;
    }
    this.changeDetectorRef.detectChanges();
  }

  setBirdsEyeView(event: any) {
    this.birdsEyeView = event;
    const marker = new google.maps.Marker({
      map: this.birdsEyeView,
      position: this.defaultPlace,
      animation: google.maps.Animation.DROP,
    });
  }

  setStreetView(event: any) {
    this.streetView = event;
  }

  getLocalTrends(postalCode) {
    let address = {
      city: this.propertyState.activePropertyDetailsValue['basic'].City,
      state: this.propertyState.activePropertyDetailsValue['basic'].StateOrProvince,
      street: this.propertyState.activePropertyDetailsValue['basic'].StreetAddress,
      zip: this.propertyState.activePropertyDetailsValue['basic'].PostalCode,
    };
    if (this.appState.authTokenValue?.idToken && this.userState.userDetails?.roles?.includes('investor')) {
      if (!this.functionCalled) {
        this.logUserActivity(
          constant.LOGGER.propertyDetails.category,
          constant.LOGGER.propertyDetails.action.viewLocalMarketInsights.key,
          this.propertyState.activePropertyDetailsValue['basic'].PMXPropertyId,
          address
        );
        this.functionCalled = true;
      }
    }

    this.localTrendsLoaderStatus = true;
    this.activeLocalTrendsForZip = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://apps.propmix.io/mci/?' +
      'auth_token=' +
      this.propmixAccessToken +
      '&zip=' +
      postalCode +
      '&monthsback=18&showGraphs=%5BMedianListPrice,MedianSoldPrice,DOM,MedianSoldPricePerSqFt,' +
      'Inventory,MedianListPriceVsMedianSoldPrice,InventoryAndAbsorptionRate%5D&head=&map=false&column=2&formfields='
    );
    this.localTrendsLoaderStatus = false;
    this.changeDetectorRef.detectChanges();
  }

  onCloseLocalTrends() {
    this.changeDetectorRef.detectChanges();
  }

  scroll(id) {
    let panel: any;
    panel = document.getElementById(id);
    if (panel) {
      panel.scrollIntoView(true);
    }
    this.changeDetectorRef.detectChanges();
  }

  getSchoolDistrictsForProperty() {
    this.propertySheetFacade.setSchoolInformationForProperty(this.activePropertyDetails);
  }

  onCloseSchoolInfo() {
    this.changeDetectorRef.detectChanges();
  }

  trackByFn(index, item) {
    return index;
  }

  showMultipleDetails(property) {
    this.propertySheetFacade.openPropertyDetailsSheet(property);
    this.showMultipleProperty = false;
  }
  onClose() {
    this.functionCalled = false;
    this.singlePropertyDetail.nativeElement.scrollTop = 0;
    this.sidenav.close();
    this.closePropertyDetailSheet();
    this.currentDiv = 'overview';
    // this.workspaceState.initiateSubjectPropertyValue = false;
    this.localTrendsLoaderStatus = false;
    if (this.localTrendsExpansionPanel) {
      this.localTrendsExpansionPanel?.close();
    }
    if (this.schoolExpansionPanel && this.schoolExpansionPanel.close) {
      this.schoolExpansionPanel.close();
    }
    this.myHomeState.isMyHomeAddressValue = false;
    this.activePropertyDetails = {};
    this.activeAVMHistory = [];
    this.setStreetViewEvent = null;
    this.isStreetView = true;
    this.defaultPlace = null;
    this.setBirdsEyeViewEvent = null;
    this.AVMHistoryDetails = [];
    this.activeLocalTrendsForZip = null;
    this.activeTransactionHistory = [];
    // = new MatTableDataSource<TransactionDetails>([]);
    this.activeSchoolDistrictsForProperty = [];
    this.activeSchoolsForProperty = [];
    this.totalOwnerEquity = null;
    this.showMultipleProperty = false;
    this.multiplePropertyList = [];
    this.activeDistressedProperty = null;
    this.distressedPropertyStatus = false;
    this.auctionList = [];
    this.auctions = [];
    this.activeHomeValue = null;
    this.imageGalleryState.imgGalleryListValue = [];
    this.listingImages = [];
    this.listingData = {};
    this.activePropertyFlag = false;
    this.activePropertyDetailsFlag = false;
    this.propertyState.mlsImageValue = {};
  }

  closePropertyDetailSheet() {
    this.propertyState.propertyDetailsSheetInput = null;
    this.propertyState.activePropertyDetailsValue = {};
    const queryParams = this.activatedRoute.snapshot.queryParams;
    let updatedUrl = this.router.createUrlTree([], { queryParams: queryParams }).toString();
    this._location.replaceState(updatedUrl);
    this.dealAnalysisState.dealListValue = null;
  }
  followProperty(property) {
    if (this.authorizeService.canAct('favorites')) {
      if (property.isFavorite) {
        if (property.PMXPropertyId) {
          const inputParams = {
            PMXPropertyId: property.PMXPropertyId,
          };
          this.propertySheetFacade
            .unFollowFavorite(inputParams)
            .pipe(takeUntil(this.onDestroyNotifier$))
            .subscribe((response: any) => {
              //console.log('$#%@ detail-sheet-component-1'); // do not remove
              if (response && response.status && response.status === 'success') {
                property.isFavorite = false;
                this.changeDetectorRef.detectChanges();
              }
            });
        }
      } else {
        if (property.StateOrProvince && property.PMXPropertyId) {
          const inpuParams = {
            state: property.StateOrProvince,
            PMXPropertyId: property.PMXPropertyId.toString(),
          };
          this.propertySheetFacade
            .addFavorite(inpuParams)
            .pipe(takeUntil(this.onDestroyNotifier$))
            .subscribe((response: any) => {
              if (response && response.status && response.status === 'success') {
                property.isFavorite = true;
                this.changeDetectorRef.detectChanges();
              }
            });
        }
      }
    }
  }

  viewImages(property) {
    this.imageGalleryState.imgGalleryListValue = property;
  }

  logPropertyDetailActivity(category, action, propertyInfomation) {
    let loggerParams = {
      category: category,
      action: action,
    };
    // let inputData = {
    //   PMXPropertyId: PMXPropertyId,
    // };
    this.activityLoggerService.logActivity(loggerParams, propertyInfomation);
  }

  copyTextToClipboard(field) {
    const selectedField = this.renderer.selectRootElement(this.inviteUrlField['nativeElement']);
    try {
      selectedField.focus();
      selectedField.select();
      document.execCommand('copy');
      selectedField.setSelectionRange(0, 0);
      selectedField.blur();
      this.copyBtnText = 'copied';
      setTimeout(() => {
        this.copyBtnText = 'copy';
      }, 2000);
    } catch (err) {
      console.error('Could not copy');
    }
  }

  propertyDetailsShare() {
    const matDialogRef = this.matDialog.open(LinkShareComponent, {
      panelClass: 'Contact-Schedule',
      width: '500px',
      height: 'auto',
      disableClose: true,
      hasBackdrop: true,
      data: {
        copyUrl: this.copyURL,
        shareUrl: this.shareUrl,
        enableSocialShare: true,
        enableCopyLink: true,
        proceedAction: (sendData) => {
          let data = {
            propertyId: this.PMXPropertyId,
            propertyUrl: this.shareUrl,
            propertyStreetAddress: this.activePropertyDetails.Address,
            propertyCity: this.activePropertyDetails.City ? this.listingData.City : this.activePropertyDetails.City,
            recipientName: sendData.recipientName,
            recipientEmail: sendData.recipientEmail,
            name: sendData.senderName,
            email: sendData.senderEmail,
          };
          this.sharePropertyService
            .sharePropertyDetails(data)
            .pipe(takeUntil(this.onDestroyNotifier$))
            .subscribe((res: any) => {
              this.openSnackBar('Property shared successfully', 'snackbar-success');
              matDialogRef.close();
            });
        },
      },
    });
  }
  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }

  triggerPropertyDetailsSheetInput(propertyInfo, avoidListing) {
    let propertyTypes;
    if (avoidListing == false) propertyTypes = ['basic', 'listing', 'avm', 'mortgage', 'transaction', 'distressed'];
    else propertyTypes = ['basic', 'avm', 'mortgage', 'transaction', 'distressed'];
    if (propertyInfo?.PMXPropertyId && propertyInfo?.Lst_SI_ListingId) {
      this.propertyService.getPropertyDetails({
        pmxPropertyId: propertyInfo.PMXPropertyId,
        listingId: propertyInfo.Lst_SI_ListingId,
        type: propertyTypes,
        ...(this.userState.userDetails?.roles[0] === 'investor' && { userId: this.userState.userDetails?.id }),
      });
    } else if (propertyInfo?.PMXPropertyId && propertyInfo?.ListingId) {
      this.propertyService.getPropertyDetails({
        pmxPropertyId: propertyInfo.PMXPropertyId,
        listingId: propertyInfo.ListingId,
        type: propertyTypes,
        ...(this.userState.userDetails?.roles[0] === 'investor' && { userId: this.userState.userDetails?.id }),
      });
    } else if (propertyInfo?.PMXPropertyId && !propertyInfo?.Lst_SI_ListingId) {
      this.propertyService.getPropertyDetails({
        pmxPropertyId: propertyInfo.PMXPropertyId,
        type: propertyTypes,
        ...(this.userState.userDetails?.roles[0] === 'investor' && { userId: this.userState.userDetails?.id }),
      });
    } else if (propertyInfo.address) {
      const addressComponents = this.propertyService.getAddressComponentsFromStandardAddress(propertyInfo.address);
      let addressObj: any = {
        streetAddress: addressComponents.StreetAddress,
        city: addressComponents.City,
        state: addressComponents.State,
        zip: addressComponents.PostalCode,
      };
      this.propertyService.getPropertyDetails({
        address: addressObj,
        type: propertyTypes,
        ...(this.userState.userDetails?.roles[0] === 'investor' && { userId: this.userState.userDetails?.id }),
      });
    } else {
      let addressObj: any = {
        streetAddress: propertyInfo?.StreetAddress
          ? propertyInfo.StreetAddress
          : propertyInfo?.UnparsedAddress
            ? propertyInfo.UnparsedAddress
            : '',
        city: propertyInfo.City,
        state: propertyInfo?.State
          ? propertyInfo.State
          : propertyInfo?.StateOrProvince
            ? propertyInfo.StateOrProvince
            : '',
        zip: propertyInfo.PostalCode,
      };
      let params = {
        address: addressObj,
        type: propertyTypes,
        ...(this.userState.userDetails?.roles[0] === 'investor' && { userId: this.userState.userDetails?.id }),
      };

      this.propertyService.getPropertyDetails(params);
    }
  }

  detectIOS() {
    let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    return isIOS;
  }

  onShow() {
    this.showMore = !this.showMore;
  }

  calculateDays(listingData) {
    if (
      listingData.StandardStatus === 'Active' ||
      listingData.StandardStatus === 'Active Under Contract' ||
      listingData.StandardStatus === 'Pending'
    ) {
      return listingData.ListingContractDate ? moment().diff(listingData.ListingContractDate, 'days') + ' days' : '--';
    } else {
      return '--';
    }
  }

  isBeforeLastAuctionDate(Fcl_lastauctionDate: string): boolean {
    return Fcl_lastauctionDate && moment().isBefore(Fcl_lastauctionDate, 'day');
  }

  preApprovedAction() {
    this.agentContactService.preApprovedAction();
    this.agentContactState.loanApplicationUrlRequestStatus$
      .pipe(take(2), takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        this.loanApplicationUrlRequestInProgress = res;
      });
    this.loanApplicationUrlRequestInProgress = false;
  }

  propertySheetUrl(property) {
    const addressParts = property.Address.split(' ');
    let streetNumber = addressParts[0];
    let streetName = addressParts.slice(1, -4).join('-').toUpperCase();
    streetName = streetName.replace(/,$/, '');
    let propertySubType = property?.PropertySubType ?? ''; // Default to an empty string if null or undefined
    propertySubType = propertySubType.replace(/ /g, '-');
    // let propertySubType = property?.PropertySubType.replace(/ /g, '-');
    const unitIndex = streetName.indexOf('#');
    if (unitIndex !== -1) {
      streetName = streetName.slice(0, unitIndex); // Remove everything starting from '#'
    }
    streetName = streetName.replace(/\s+/g, '-').toUpperCase();
    streetName = streetName.replace(/-+$/, '');
    let unit: any;
    if (addressParts.includes('#')) {
      const unitIndex = addressParts.indexOf('#');
      unit = `unit-${addressParts[unitIndex + 1]}`;
      unit = unit.replace(/,$/, '');
    }
    let baseUrl = `/workspace/map/${property.StateOrProvince}/${property.City}/${streetNumber}-${streetName}-${property.PostalCode}/${unit}/${propertySubType}/${property.PMXPropertyId}`;
    let urlParts = baseUrl.split('/');
    urlParts = urlParts.filter((part) => part !== 'undefined');
    let url = urlParts.join('/');
    return url;
  }

  schedulePropertyVisit() {
    if (this.appState.authTokenValue?.idToken) {
      if (!this.authorizeService.canAct('agentContact')) {
        return false;
      }
    }
    this._zone.run(() => {
      const matDialogRef = this.matDialog.open(AgentContactScheduleComponent, {
        panelClass: 'Contact-Schedule',
        width: '500px',
        disableClose: true,
        hasBackdrop: true,
        data: {
          proceedAction: (schedulePropertyVisitData) => {
            this.agentContactState.contactAgentRequestActionStatusValue = true;
            let params = {
              investor: schedulePropertyVisitData,
              agentId: this.agentInfo['id'],
              property: {
                PMXPropertyId: this.propertyState.activePropertyDetailsValue['basic'].PMXPropertyId,
                fullAddress: this.fullAddress,
                streetAddress: this.propertyState.activePropertyDetailsValue['basic'].StreetAddress,
                city: this.propertyState.activePropertyDetailsValue['basic'].City,
                state: this.propertyState.activePropertyDetailsValue['basic'].StateOrProvince,
                zipCode: this.propertyState.activePropertyDetailsValue['basic'].PostalCode,
                mlsName: this.listingData.MlsBoard,
                mlsNumber: this.listingData.MlsListingNumber,
                listPrice: this.listingData.ListPrice,
              },
              address: this.address,
            };
            this.logUserActivity(
              constant.LOGGER.propertyDetails.category,
              constant.LOGGER.propertyDetails.action.showingInit.key,
              params.property.PMXPropertyId,
              params.address,
              params.agentId,
              this.fullAddress
            );

            this.agentContactService
              .schedulePropertyVisit(params)
              .pipe(takeUntil(this.onDestroyNotifier$))
              .subscribe(
                (response: any) => {
                  this.agentContactState.contactAgentRequestActionStatusValue = false;
                  matDialogRef.close();
                  this.openSnackBar(response.message, 'snackbar-success');
                  if (response.data && response.data.redirectUrl) {
                    window.open(response.data.redirectUrl);
                  }
                },
                (error) => {
                  this.agentContactState.contactAgentRequestActionStatusValue = false;
                  this._zone.run(() => {
                    matDialogRef.close();
                  });
                }
              );
          },
        },
      });
    });
  }

  logUserActivity(category, action, PMXPropertyId, address, agentId?, fullAddress?) {
    let loggerParams = {
      category: category,
      action: action,
    };
    let inputData = {
      PMXPropertyId: PMXPropertyId,
      agentId: agentId,
      address: address,
      fullAddress: fullAddress,
    };
    this.activityLoggerService.logActivity(loggerParams, inputData);
  }

  private generateDisclaimer(template: string, { mlsName }): string {
    if (template) {
      template = template.replace(/<#copyYear#>/gi, new Date().getFullYear().toString());
      template = template.replace(/<#mlsName#>|#mlsName#/gi, mlsName);
    }
    return template;
  }

  goToOverview() {
    this.overview.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  goToPropertyDetails() {
    this.propertyInformation.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  goToValueAndTaxInfo() {
    this.valueAndTaxInformation.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  goToSchools() {
    this.schoolsInformation.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  goToTrends() {
    this.trends.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  goToMore() {
    this.more.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  answerQuestions() {
    this.dialog.closeAll();
    if (!this.userState.userDetails) {
      this.openLogInDialog();
    } else {
      if (this.userState.userDetails?.emailVerificationDate === null) {
        this.profileService.getUserDetails().subscribe(
          () => this.openActivePropertyClaimDialog(this.populateAddress),
          () => this.openActivePropertyClaimDialog(this.populateAddress)
        );
      } else {
        this.openAppPropertyDialog(this.populateAddress);
      }
    }
  }

  manageMyProperty() {
    this.router.navigate(['my-property'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        id: this.propertyInfo?.homeId || this.activePropertyDetails?.homeId,
      },
    });
    this.onClose();
    // }
  }

  openContactConsultant() {
    this.dialog.closeAll();

    if (this.userState.userDetails?.emailVerificationDate === null) {
      this.profileService.getUserDetails().subscribe(
        () => this.openActivateRequestDialog(),
        () => this.openActivateRequestDialog()
      );
    } else {
      this.openSellingAgentDialog();
    }
  }

  openActivateRequestDialog() {
    this.dialog.closeAll();

    this.userInfo = this.userState.userDetails;
    if (this.userInfo && this.userInfo.emailVerificationDate == null) {
      this.dialog.open(ActivateModalComponent, {
        width: '385px',
        disableClose: true,
        hasBackdrop: true,
      });
    } else {
      this.openSellingAgentDialog();
    }
  }

  openActivePropertyClaimDialog(address) {
    this.dialog.closeAll();

    this.userInfo = this.userState.userDetails;
    if (this.userInfo && this.userInfo.emailVerificationDate == null) {
      this.dialog.open(ActivateModalComponent, {
        width: '385px',
        disableClose: true,
        hasBackdrop: true,
      });
    } else if (this.appState.authTokenValue?.idToken) {
      this.openAppPropertyDialog(address);
    }
  }

  calculateHoaFee(fee, frequency) {
    if (fee != 0) {
      const feeValues = [{ fee: fee, recurrence: frequency }];
      return this.dealAnalysisService.getHoaFee(feeValues) / 12;
    }
    return 0;
  }

  openSellingAgentDialog() {
    this.dialog.closeAll();

    this._zone.run(() => {
      const matDialogRef = this.dialog.open(ContactSellingConsultantComponent, {
        panelClass: 'Contact-Schedule',
        width: '500px',
        disableClose: true,
        hasBackdrop: true,
        data: {
          proceedAction: (sellingConsultantData) => {
            this.agentContactState.contactAgentRequestActionStatusValue = true;
            const params = {
              investor: sellingConsultantData,
              agentId: this.agentInfo['id'],
              property: {
                PMXPropertyId: this.propertyState.activePropertyDetailsValue['basic'].PMXPropertyId,
                fullAddress: this.fullAddress,
                streetAddress: this.propertyState.activePropertyDetailsValue['basic'].StreetAddress,
                city: this.propertyState.activePropertyDetailsValue['basic'].City,
                state: this.propertyState.activePropertyDetailsValue['basic'].StateOrProvince,
                zipCode: this.propertyState.activePropertyDetailsValue['basic'].PostalCode,
                mlsName: this.listingData.MlsBoard,
                mlsNumber: this.listingData.MlsListingNumber,
                listPrice: this.listingData.ListPrice,
              },
              address: this.address,
            };

            this.logUserActivity(
              constant.LOGGER.propertyDetails.category,
              constant.LOGGER.propertyDetails.action.requestSellingAgentInit.key,
              params.property.PMXPropertyId,
              params.agentId,
              params.address,
              this.fullAddress
            );

            this.agentContactService
              .requestSellingAgent(params)
              .pipe(takeUntil(this.onDestroyNotifier$))
              .subscribe(
                (response: any) => {
                  this.agentContactState.contactAgentRequestActionStatusValue = false;
                  this.openSnackBar(response.message, 'snackbar-success');
                  this._zone.run(() => {
                    matDialogRef.close();
                  });
                },
                (error) => {
                  this.agentContactState.contactAgentRequestActionStatusValue = false;
                  this._zone.run(() => {
                    matDialogRef.close();
                  });
                }
              );
          },
        },
      });
    });
  }

  openLogInDialog() {
    this.matDialog.open(SignupDialogComponent, {
      width: '385px',
      disableClose: true,
      hasBackdrop: true,
    });
  }
  openAppPropertyDialog(addressComponent) {
    this.dialog.open(CreateMyHomeDialogueComponent, {
      width: '385px',
      disableClose: true,
      data: addressComponent,
    });
  }
}
